import { UpdateWithdrawalRequestInterface } from '@/data/payload/contracts/WithdrawalRequest'

export class UpdateWithdrawalRequest implements UpdateWithdrawalRequestInterface {
  public withdrawalStatusId?: string
  public note?: string

  constructor(
    withdrawalStatusId?: string,
    note?: string
  ) {
    this.withdrawalStatusId = withdrawalStatusId
    this.note = note
  }

  public toPayload(): string {
    const data = {
      withdrawal_status_id: this.withdrawalStatusId,
      note: this.note
    }

    return JSON.stringify(data);
  }
}
