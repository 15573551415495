















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'

@Component({
  components: {
    Modal,
    TextInput,
    Button,
  },
})
export default class ModalRejected extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: 0 }) private maxCount!: number
  @Prop({ default: '' }) private value!: string
}
