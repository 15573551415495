








































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/WithdrawalsController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import dayjs from 'dayjs'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import { Utils, WITHDRAWAL_PAGINATION } from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Stepper from '@/app/ui/components/Stepper/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import ModalRejected from '@/app/ui/views/Withdrawal/components/Modals/ModalRejected.vue'
import ModalConfirmation from '@/app/ui/views/Withdrawal/components/Modals/ModalConfirmation.vue'
import { Withdrawal } from '@/domain/entities/Withdrawal'
import IconProfile from '@/app/ui/assets/icon_profile.vue'
import IconWarningCircle from '@/app/ui/assets/icon_warning_circle.vue'
import { Dictionary } from 'vue-router/types/router'
import { EnumStatusWithdrawal } from '@/app/infrastructures/misc/Constants/withdrawal'

export interface WithdrawalStatusListInterface {
  label: string;
  position: string;
}

dayjs.extend(dayjsRelativeTime)
@Component({
  components: {
    Button,
    Modal,
    TextInput,
    LoadingOverlay,
    DataTable,
    PaginationNav,
    Stepper,
    Badge,
    ModalRejected,
    ModalConfirmation,
    IconProfile,
    IconWarningCircle
  },
})
export default class WithdrawalDetailPage extends Vue {
  controller = controller
  isGuest = Utils.isGuest()
  isFinance = Utils.isFinance()
  isCNM = Utils.isCNM()
  isSuperAdmin = Utils.isSuperAdmin()
  rejectConfirmationModal = false
  approveConfirmationModal = false
  successModal = false
  successModalMsg = {
    title: '',
    body: '',
  }
  form = {
    note: '',
    withdrawalStatus: '',
  }

  parameters = {
    page: 1,
    perPage: WITHDRAWAL_PAGINATION,
  }

  headers = [
    { title: 'Update Date', width: '20%' },
    { title: 'Description', width: '38%' },
    { title: 'Amount', width: '15%' },
    { title: 'Sisa Saldo', width: '15%' },
    { title: 'Status', width: '12%' },
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || WITHDRAWAL_PAGINATION
      }
    }
    this.fetchDetail()
  }

  @Watch('controller.statusUpdateWithdrawal')
  onStatusUpdateWithdrawalChanged(status: string): void {
    switch (status) {
      case 'REJECTED':
        this.successModalMsg = {
          title: 'Rejected',
          body: 'Withdrawal request has been rejected and informed to the user',
        }
        this.successModal = true
        break;
      case 'APPROVED':
        this.successModalMsg = {
          title: 'Approved',
          body: 'Withdrawal request has been approved and cash has been added to the user’s saldo',
        }
        this.successModal = true
        break;
    }
    controller.setStatusUpdateWithdrawal('')
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get params(): Record<string, string | number>  {
    return {
      ...this.parameters,
    }
  }

  get withdrawalStatus(): string | undefined {
    return controller.withdrawalDetail.withdrawalStatusId
  }

  get withdrawalStatusList(): Array<WithdrawalStatusListInterface> {
    const statuses = controller.constants.withdrawalStatuses.filter((item) => {
      return !((this.withdrawalStatus === EnumStatusWithdrawal.REJECTED && item.status === EnumStatusWithdrawal.APPROVED) ||
        (this.withdrawalStatus === EnumStatusWithdrawal.REJECTED && item.status === EnumStatusWithdrawal.FAILED) ||
        (this.withdrawalStatus ===  EnumStatusWithdrawal.FAILED && item.status === EnumStatusWithdrawal.REJECTED) ||
        (this.withdrawalStatus === EnumStatusWithdrawal.APPROVED && item.status === EnumStatusWithdrawal.REJECTED) ||
        (this.withdrawalStatus === EnumStatusWithdrawal.PENDING && item.status === EnumStatusWithdrawal.REJECTED));
    })

    const currentIndex = statuses.findIndex(
      (item) =>
        (item.status === EnumStatusWithdrawal.PENDING && this.withdrawalStatus === EnumStatusWithdrawal.PENDING) ||
        (item.status === EnumStatusWithdrawal.APPROVED && this.withdrawalStatus === EnumStatusWithdrawal.APPROVED) ||
        (item.status ===  EnumStatusWithdrawal.FAILED && this.withdrawalStatus === EnumStatusWithdrawal.FAILED) ||
        (item.status === EnumStatusWithdrawal.REJECTED && this.withdrawalStatus === EnumStatusWithdrawal.REJECTED)
    )

    return statuses.map((item, idx) => {
      if (idx < currentIndex) {
        item.position = 'past'
      } else if (idx > currentIndex) {
        item.position = 'future'
      } else {
        item.position = 'current'
      }

      return {
        label: item.label,
        position: item.position,
      }
    })
  }

  get claimStatusId(): string {
    if (this.withdrawalStatus === EnumStatusWithdrawal.PENDING) {
      return "warning"
    } else if (this.withdrawalStatus === EnumStatusWithdrawal.APPROVED) {
      return "success"
    } else if (this.withdrawalStatus === EnumStatusWithdrawal.PROGRESS) {
      return "warning"
    } else {
      return "error"
    }
  }

  get withdrawalBalanceDataTable(): (string | undefined)[][] | undefined {
    return controller.withdrawalDetail.history?.map((history) => [
      this.formatDate(<string>history.timestamp),
      history.description,
      this.formatCurrency(<number>history.deltaAmount),
      this.formatCurrency(<number>history.balanceAmount),
      history.status,
    ]);
  }

  get withdrawalDetail(): Withdrawal {
    return controller.withdrawalDetail
  }

  get isShowButton(): boolean {
    return (this.withdrawalDetail.withdrawalStatusId === 'PENDING' && (this.isSuperAdmin || this.isFinance))
  }

  get getBankName(): string {
    return this.withdrawalDetail.bankId ? this.withdrawalDetail.bankId.toUpperCase() : ''
  }

  private fetchDetail(): void {
    this.controller.getWithdrawalDetail({ id: this.$route.params.id, params: this.params})
  }

  private formatDate(date: string): string {
    return Utils.formatTimeZone(Utils.formatDate(date, 'DD MMM YYYY HH:mm Z'))
  }

  private formatCurrency(input: number): string {
    return `Rp ${Utils.currencyDigit(input)}`
  }

  private countDown(date: string | undefined): string {
    const dateDiff = dayjs(date).add(7200, 'minutes').diff(dayjs(), 'minutes')

    return this.withdrawalDetail.withdrawalStatusId === EnumStatusWithdrawal.APPROVED
      ? 'Approved'
      : this.withdrawalDetail.withdrawalStatusId === EnumStatusWithdrawal.REJECTED
        ? 'Rejected'
        : dateDiff > 0
          ? `${dayjs(date).add(120, 'hours').toNow(true)} left for action`
          : `Exceed ${dayjs(date).add(dateDiff, 'minutes').fromNow(true)}`
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.fetchDetail()
  }

  private onRejectStatus(): void {
    if (
      !this.form.note ||
      this.form.note.length < 10 ||
      this.form.note.length > controller.constants.rejectMessageLength
    ) {
      this.$notify({
        title: 'Reject Withdrawal Invalid',
        text: `Please input reason to reject note and must between 10 to ${controller.constants.rejectMessageLength} chars long`,
        type: 'error',
        duration: 5000,
      })
    } else {
      this.rejectConfirmationModal = false
      controller.updateWithdrawal({
        withdrawalId: this.$route.params.id,
        withdrawalStatusId: this.form.withdrawalStatus,
        note: this.form.note,
      })
    }
  }

  private onApproveStatus(): void {
    this.approveConfirmationModal = false
    controller.updateWithdrawal({
      withdrawalId: this.$route.params.id,
      withdrawalStatusId: this.form.withdrawalStatus,
      note: '',
    })
  }

  private onChangeInputModal(val: string): void {
    this.form.note = val
  }

  private formatWithdrawalStatus(withdrawalStatusId: string, updatedBy: string): string {
    if (updatedBy) {
      return withdrawalStatusId === 'PENDING' ? 'REQUEST BY ' + updatedBy : withdrawalStatusId + ' BY ' + updatedBy
    }
    return withdrawalStatusId === 'PENDING' ? 'REQUEST' : withdrawalStatusId
  }

  beforeDestroy(): void {
    controller.setStatusUpdateWithdrawal('')
  }
}
