import { Pagination } from '@/domain/entities/Pagination'
import { Withdrawal, Withdrawals } from '@/domain/entities/Withdrawal'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { container } from 'tsyringe'
import { WithdrawalPresenter } from '@/app/ui/presenters/WithdrawalPresenter'
import { Utils, WITHDRAWAL_PAGINATION } from '@/app/infrastructures/misc'
import Vue from 'vue'
import { UpdateWithdrawalRequest } from '@/data/payload/api/WithdrawalRequest'

export interface WithdrawalState {
  isLoading: boolean
  paginationData: Pagination
  withdrawalData: Withdrawal[]
  withdrawalDetail: Withdrawal
}

export interface WithdrawalParamsInterface {
  page?: number
  perPage?: number
  keyword?: string
  sortBy?: string
  filterBy?: string
}

@Module({ namespaced: true, store, name: 'withdrawal', dynamic: true, stateFactory: true, })
class WithdrawalController extends VuexModule implements WithdrawalState {
  private presenter: WithdrawalPresenter = container.resolve(WithdrawalPresenter)
  public isLoading = false
  public paginationData = new Pagination(1, WITHDRAWAL_PAGINATION)
  public withdrawalData = [new Withdrawal()]
  public withdrawalDetail = new Withdrawal()
  public statusUpdateWithdrawal = ''

  public constants = {
    rejectMessageLength: 90,
    withdrawalStatuses: [
      {
        status: 'PENDING',
        actor: 'CUSTOMER',
        label: "Request",
        position: 'past',
      },
      {
        status: 'APPROVED',
        label: 'Done',
        position: 'future',
      },
      {
        status: 'FAILED',
        label: 'Failed',
        position: 'future',
      },
      {
        status: 'REJECTED',
        label: 'Rejected',
        position: 'future',
      },
    ],
  }

  @Action({ rawError: true })
  public getWithdrawalList(params: WithdrawalParamsInterface): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setWithdrawalData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Withdrawal Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getWithdrawalDetail(form : {
    id: string
    params: Record<string, any>
  }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .get(form.id , formattedParams)
      .then(res => {
        this.setWithdrawalDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Withdrawal Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateWithdrawal(form: {
    withdrawalId: string
    withdrawalStatusId: string
    note: string
  }): void {
    this.setLoading(true)

    this.presenter
      .update(
        form.withdrawalId,
        new UpdateWithdrawalRequest(
          form.withdrawalStatusId,
          form.note
        )
      )
      .then(() => {
        this.setStatusUpdateWithdrawal(form.withdrawalStatusId)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Withdrawal Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setWithdrawalData(withdrawals: Withdrawals): void {
    this.paginationData = <Pagination>withdrawals.pagination
    this.withdrawalData = <Array<Withdrawal>>withdrawals.data
  }

  @Mutation
  private setWithdrawalDetail(withdrawal: Withdrawal): void {
    this.paginationData = <Pagination>withdrawal.pagination
    this.withdrawalDetail = withdrawal
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  public setStatusUpdateWithdrawal(status: string): void {
    this.statusUpdateWithdrawal = status
  }
}

export default getModule(WithdrawalController)
