export enum EnumStatusWithdrawal {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    PROGRESS = 'ONPROGRESS',
    FAILED = 'FAILED',
    REJECTED = 'REJECTED',
}

export const withdrawalStatuses = [
  {
    label: 'Request',
    value: EnumStatusWithdrawal.PENDING,
  },
  {
    label: 'Approved',
    value: EnumStatusWithdrawal.APPROVED,
  },
  {
    label: 'Failed',
    value: EnumStatusWithdrawal.FAILED,
  },
  {
    label: 'Rejected',
    value: EnumStatusWithdrawal.REJECTED,
  }
]
